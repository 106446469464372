import CssBaseline from "@material-ui/core/CssBaseline";
import EstablishmentsPage from "../components/pages/establishments/index";
import { getGlobals } from "../src/requests/globals/getGlobals";

export async function getStaticProps() {
  const params = { lang: process.env.NEXT_PUBLIC_LANG };
  const paramsForCode = {
    lang: process.env.NEXT_PUBLIC_LANG,
    codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
  };
  const establishments = await getGlobals(
    "GET",
    "establishmentsGroup",
    params,
    null
  );
  const states = await getGlobals("GET", "states", params, null);
  const legalForms = await getGlobals("GET", "legalForms", params, null);
  const codes = await getGlobals("GET", "codes", paramsForCode, null);

  return {
    props: {
      establishments,
      states,
      legalForms,
      codes
    },
    revalidate: 10,
  };
}

export default function Home({
  establishments,
  states,
  legalForms,
  codes
}) {

  return (
    <>
        <div>
          <CssBaseline />
          <EstablishmentsPage 
                      establishments={establishments}
                      states={states}
                      legalForms={legalForms}
                      codes={codes}/>
        </div>
    </>
  );
}
